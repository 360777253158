
import React from 'react'
import Helmet from 'react-helmet'
import { injectIntl } from 'react-intl'
import SignInForm from '../components/SignIn'
import Box from '../components/Box'
import Header from '../components/Header'
import styled from "styled-components"

const BackgroundBox = styled(Box)`
  background-color: #4844a3;
  min-height: calc(100vh);
`

const SignInPage = ( props ) => {
  const intl = props.intl
  const location = props.location
  return(
  <BackgroundBox>
    <Helmet title={intl.formatMessage({ id: 'signIn.intro' })} />
    <Header
      breadcrumbs={[
        {
          title: intl.formatMessage({ id: 'globals.courseOverview' }),
          path: intl.formatMessage({ id: 'routes.LANDING' }),
        },
        {
          title: intl.formatMessage({ id: 'globals.signIn' }),
          path: intl.formatMessage({ id: 'routes.SIGN_IN' }),
        },
      ]}
    />
    <SignInForm intl={intl} location={location} />
  </BackgroundBox>
  )
}
export default injectIntl(SignInPage)
